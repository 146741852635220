import { useEffect, useRef } from "react";
import { Feature, Map, View } from 'ol';
import { Circle as CircleStyle, Fill, Stroke, Style, Text } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Select } from 'ol/interaction';
import { LineString, MultiLineString, Point } from 'ol/geom';
import { OverviewMap, defaults as defaultControls } from 'ol/control.js';


function MapComponent({ nodes, edges, selectId, edgeType }) {

    const ref = useRef(null);
    const mapRef = useRef(null);

    useEffect(() => {
        if (ref.current && !mapRef.current) {

            const nodesLayers = getNodesLayers(nodes)
            const edgesLayers = getEdgesLayers(edges, edgeType)

            // const overviewMapControl = new OverviewMap({
            //     // see in overviewmap-custom.html to see the custom CSS used
            //     className: 'ol-overviewmap ol-custom-overviewmap',
            //     layers: [...nodesLayers],
            //     collapseLabel: '\u00BB',
            //     label: '\u00AB',
            //     collapsed: false,
            // });

            const view = new View({
                center: [4000, 4000],
                zoom: 14,
            });
            mapRef.current = new Map({
                // controls: defaultControls().extend([overviewMapControl]),
                layers: [...nodesLayers, edgesLayers],
                target: ref.current,
                view: view,
            });

            const select = getSelect()

            mapRef.current.addInteraction(select);
            select.on('select', function (e) {
                if (e.selected[0]) {
                    const selectedId = e.selected[0].getId();
                    if (selectedId) {
                        // const geometry: any = e.selected[0].getGeometry()
                        selectId(selectedId)
                        // view.animate({ center: geometry.getCoordinates(), zoom: 20 });
                    }
                } else {
                    selectId(null)
                }
            });

        }
    }, [ref, mapRef]);

    useEffect(() => {

        if (mapRef.current) {

            const collection = mapRef.current.getLayers();
            const layersCount = collection.getLength() - 1;
            collection.removeAt(layersCount);
            const edgesLayers = getEdgesLayers(edges, edgeType);
            collection.setAt(layersCount, edgesLayers);
            // mapRef.current.setLayers([...nodesLayers, edgesLayers])
        }
    }, [edges]);

    return <div ref={ref} style={{
        width: '100%', height: '100%', backgroundColor: "black"
    }} />;
};

function getNodesLayers(nodes) {

    // const colors = [
    //     "#DF6C92",
    //     "#90CACB",
    //     "#C39DF9",
    //     "#7F83EF",
    //     "#F4CB79",
    //     "#CF9185",
    // ];
    const colors = {
        "localization": "#DF6C92",
        "response to stimulus": "#90CACB",
        "biological regulation": "#C39DF9",
        "multicellular organismal process": "#7F83EF",
        "cellular process": "#F4CB79",
        "developmental process": "#CF9185",
        "biological process involved in interspecies interaction between organisms": "#DF6C43",
        "metabolic process": "#40CACB",
        "reproductive process": "#C10DF9",
        "immune system process": "#7F11EF",
        "signaling": "#F4DD20",
        "": "#FF0000",
        "locomotion": "#AA6B60",
    }


    // const stylesNodes = colors.map(
    //     (color, idx) => [
    //         new Style({
    //             image: new CircleStyle({
    //                 radius: 6 - 0.75 * idx,
    //                 fill: new Fill({
    //                     color: colors[idx],
    //                 }),
    //             }),
    //         }),
    //     ]
    // );
    //
    // console.log('stylesNodes', stylesNodes);

    const styles = {}

    const nodeLabels = [];

    const sourceNodes = nodes.map(
        (nodeGroup, idx) => new VectorSource({
            features: nodeGroup.map(node => {
                nodeLabels.push(node)
                const feature = new Feature({
                    geometry: new Point(node.slice(1, 3))
                });
                const radius = Math.floor(node[3] / 6 + 4);
                const color = colors[node[4]];
                if (!styles[radius]) {
                    styles[radius] = {}
                }
                if (!styles[radius][color]) {
                    styles[radius][color] = new Style({
                        image: new CircleStyle({
                            radius: radius,
                            fill: new Fill({
                                color: color,
                            }),
                        }),
                        // text: node[3] == 0 ? new Text({
                        //     text: node[0],
                        //     fill: new Fill({color: '#ffcc33'}),
                        // }) : null,
                    });
                }
                feature.setId(node[0]);
                feature.setStyle(styles[radius][color]);
                return feature;
            })
        })
    );


    const labelStyle = new Style({
        text: new Text({
            font: '12px Calibri,sans-serif',
            overflow: true,
            fill: new Fill({
                color: '#fff',
            }),
            stroke: new Stroke({
                color: '#000',
                width: 3,
            }),
        }),
    });

    const nodeLabelsSources = new VectorSource({
        features: nodeLabels.map(node => {
            return new Feature({
                name: node[0],
                geometry: new Point(node.slice(1, 3)),
            });
        }),

    })

    const nodeLabelsLayer = new VectorLayer({
        source: nodeLabelsSources,
        style: function (feature) {
            const label = feature.get('name')
            labelStyle.getText().setText(label);
            return labelStyle;
        },
        declutter: true,
    });

    // console.log(sourceNodes)
    //
    // sourceNodes.push(
    //     new VectorSource({
    //         features: nodes[0].map(node => {
    //             // console.log(node)
    //             const feature = new Feature({
    //                 geometry: new Point(node.slice(1, 3))
    //             });
    //             feature.setId(node[0]);
    //             feature.setStyle(new Style({
    //                 // image: new CircleStyle({
    //                 //         radius: Math.floor(node[3] / 6 + 4),
    //                 //         fill: new Fill({
    //                 //             color: '#FFFFFF',
    //                 //         }),
    //                 //     }),
    //                 text: new Text({
    //                     text: node[3],
    //                     fill: new Fill({color: '#ffcc33'}),
    //                 }),
    //             }));
    //             return feature;
    //         })
    //     })
    // )
    //
    // console.log(sourceNodes)

    const nodePointsLayers = sourceNodes.map(
        (source, idx) => new VectorLayer({
            source: source,
            // style: stylesNodes[idx],
            maxResolution: 40 + 7 * idx,
        })
    )

    return [nodeLabelsLayer, ...nodePointsLayers]
    // return nodePointsLayers

}

function getNodesLayersOverview(nodes) {

    const colors = {
        "localization": "#DF6C92",
        "response to stimulus": "#90CACB",
        "biological regulation": "#C39DF9",
        "multicellular organismal process": "#7F83EF",
        "cellular process": "#F4CB79",
        "developmental process": "#CF9185",
        "biological process involved in interspecies interaction between organisms": "#DF6C43",
        "metabolic process": "#40CACB",
        "reproductive process": "#C10DF9",
        "immune system process": "#7F11EF",
        "signaling": "#F4DD20",
        "": "#FF0000",
        "locomotion": "#AA6B60",
    }

    const styles = {}

    const sourceNodes = nodes.map(
        (nodeGroup, idx) => new VectorSource({
            features: nodeGroup.map(node => {
                const feature = new Feature({
                    geometry: new Point(node.slice(1, 3))
                });
                const radius = Math.floor(node[3] / 6 + 4);
                const color = colors[node[4]];
                if (!styles[radius]) {
                    styles[radius] = {}
                }
                if (!styles[radius][color]) {
                    styles[radius][color] = new Style({
                        image: new CircleStyle({
                            radius: radius,
                            fill: new Fill({
                                color: color,
                            }),
                        }),
                    });
                }
                feature.setId(node[0]);
                feature.setStyle(styles[radius][color]);
                return feature;
            })
        })
    );

    return sourceNodes.map(
        (source, idx) => new VectorLayer({
            source: source,
            // style: stylesNodes[idx],
            // maxResolution: 40 + 7 * idx,
        })
    )

}

function getEdgesLayers(edges, edgeType) {


    const stylesEdge = [
        new Style({
            stroke: new Stroke({
                color: [0, 128, 255, 0.7],
                width: 3,
            }),
        }),

    ];

    const sourceEdges = new VectorSource({
        features: edges.map(coords => {
            if (edgeType === "direct") {
                return new Feature({
                    geometry: new LineString(coords)
                })
            } else {
                return new Feature({
                    geometry: new MultiLineString(coords)
                })
            }
        })
    });

    const layerEdges = new VectorLayer({
        source: sourceEdges,
        style: stylesEdge,
        // maxResolution: 1,
    });

    return layerEdges
}

function getSelect() {
    const selected = new Style({
        fill: new Fill({
            color: 'yellow',
        }),
        stroke: new Stroke({
            color: 'rgba(255, 255, 255, 0.7)',
            width: 2,
        }),
    });
    const selectedImage = new Style({
        image: new CircleStyle({
            radius: 8,
            fill: new Fill({
                color: 'yellow',
            }),
        }),
    });

    const selectStyle = (feature: Feature) => {
        return selectedImage
    };

    // select interaction working on "singleclick"
    const select = new Select({ style: selectStyle });
    // const select = new Select();

    // // select interaction working on "click"
    // const selectClick = new Select({
    //     condition: click,
    //     style: selectStyle,
    // });

    return select
}


export default MapComponent;

