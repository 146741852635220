import './App.css'
import { useState, useEffect } from "react";
import { Magic } from 'magic-sdk';

import MapComponent from "./map"

const hosts = {
  "prod": "https://cell-blueprint-api.deeplife.co",
  "dev": "https://cell-blueprint-api-dev.deeplife.co",
  "staging": "https://cell-blueprint-api-dev.deeplife.co",
  undefined: "http://127.0.0.1:8000"
}

const keys = {
  "prod": "pk_live_680ADA8E70177CB5",
  "dev": "pk_live_2B60340AFED2FD18",
  "staging": "pk_live_2B60340AFED2FD18",
  undefined: "pk_live_2B60340AFED2FD18"
}

const API_HOST = hosts[process.env.REACT_APP_ENV];
const MAGIC_PUBLIC_KEY = keys[process.env.REACT_APP_ENV];

const magic = new Magic(MAGIC_PUBLIC_KEY);

function App() {

  const [nodes, setNodes] = useState(null);
  const [edges, setEdges] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [user, setUser] = useState(null);
  const [didToken, setDidToken] = useState(null);
  const [edgeType, setEdgeType] = useState("direct");

  // magic.user.logout();

  useEffect(() => {
    if (didToken) {
      fetch(`${API_HOST}/nodes`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + didToken,
          }
        })
        .then(response => response.json())
        .then(data => setNodes(data.nodeGroups))
    } else {
      setNodes(null);
      setSelectedId("");
    }
  }, [didToken]);

  useEffect(() => {
    if (selectedId) {
      const ENDPOINTS = {
        "direct": "neighbors_direct",
        "routing": "neighbors_path",
      }
      const endpoint = ENDPOINTS[edgeType]
      fetch(`${API_HOST}/${endpoint}/${selectedId}`)
        .then(response => response.json())
        .then(data => setEdges(data.edges))
    } else {
      setEdges([])
    }
  }, [selectedId, edgeType]);

  return (
    <div className="App">
      <Login user={user} setUser={setUser} setDidToken={setDidToken} />
      <p></p>
      <ul>
        <li>
          <span style={{ color: '#DF6C92' }}>localization</span>
          <span style={{ color: '#90CACB' }}>response to stimulus</span>
          <span style={{ color: '#C39DF9' }}>biological regulation</span>
          <span style={{ color: '#7F83EF' }}>multicellular organismal process</span>
          <span style={{ color: '#F4CB79' }}>cellular process</span>
          <span style={{ color: '#CF9185' }}>developmental process</span>
          <span style={{ color: '#DF6C43' }}>biological process involved in interspecies interaction between organisms</span>
          <span style={{ color: '#40CACB' }}>metabolic process</span>
          <span style={{ color: '#C10DF9' }}>reproductive process</span>
          <span style={{ color: '#7F11EF' }}>immune system process</span>
          <span style={{ color: '#F4DD20' }}>signaling</span>
          <span style={{ color: '#AA6B60' }}>locomotion</span>
          <span style={{ color: '#FF0000' }}>no ontology!</span>
        </li>
      </ul>
      <p></p>
      <p></p>
      {nodes ? <MapComponent nodes={nodes} edges={edges} selectId={setSelectedId} edgeType={edgeType} /> : null}
      {nodes ? <SelectEdgeStyle edgeType={edgeType} setEdgeType={setEdgeType} /> : null}
      {selectedId ? <p style={{
        position: "absolute", top: "0",
        left: "100px"
      }}>Node selected: {selectedId}</p> : null
      }
    </div >
  );
}


export default App;

function Login({ user, setUser, setDidToken }) {

  function handleLogin(e) {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const email = formData.get('email');
    if (typeof email === 'string') {
      magic.auth.loginWithMagicLink({ email }).then(didToken => {
        setDidToken(didToken);
        magic.user.getMetadata().then(userData => setUser(userData));
      })
      // magic.user.isLoggedIn().then(isLoggedIn => {
      //   return isLoggedIn ? ) : setUser(null); return Promise.resolve(null);;
    };
    // magic.user.getIdToken().then(didToken => setDidToken(didToken));
  }

  function handleLogout(e) {
    e.preventDefault();
    magic.user.logout().then(data => { setUser(null); setDidToken(null); });
  }

  if (!user) {
    return (
      <div className="Login">
        <h1>Enter your email to login</h1>
        <form onSubmit={handleLogin}>
          <input type="email" name="email" required={true} placeholder="Enter your email" />
          <button type="submit">Login</button>
        </form>
      </div>
    )
  }
  return <div style={{
    position: "absolute",
    top: "0",
    right: "10px",
    zIndex: 10
  }}><p >Logged as {user.email} </p>
    <button type="submit" onClick={handleLogout}>Logout</button>
  </div >
}

function SelectEdgeStyle({ edgeType, setEdgeType }) {
  function handleChange(e) {
    setEdgeType(e.target.value);
  }
  return <fieldset className="select-edge-style">
    <legend >Select edge type:</legend>
    <div>
      <input type="radio" id="direct" name="drone" value="direct"
        checked={edgeType === "direct"} onChange={handleChange} />
      <label>Direct</label>
    </div>
    <div>
      <input type="radio" id="routing" name="drone" value="routing"
        checked={edgeType === "routing"} onChange={handleChange} />
      <label>Routing</label>
    </div>
  </fieldset>

}